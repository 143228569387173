<template lang="pug">
  .cancellation-fee-table
    table
      thead
        tr
          th.setting-unit.sortable(@click="handleSorting('code')")
            span {{ $t("company_system.basic_settings.cancellation_fees.code") }}
            FaIcon.icon(:icon="sortingIcon('code')")
          th.departure-prefecture.sortable(@click="handleSorting('name')")
            span {{ $t("company_system.basic_settings.cancellation_fees.name") }}
            FaIcon.icon(:icon="sortingIcon('name')")
          th.transfer-prefecture.sortable(@click="handleSorting('max_fee')")
            span {{ $t("company_system.basic_settings.cancellation_fees.max_fee") }}
            FaIcon.icon(:icon="sortingIcon('max_fee')")
          th.last-updated.sortable(@click="handleSorting('updated_at')")
            span {{ $t("company_system.basic_settings.cancellation_fees.last_updated") }}
            FaIcon.icon(:icon="sortingIcon('updated_at')")
          th.actions
      tbody
        template(v-for="item in items")
          tr(
            :key="item.id"
            @click="$emit('select-row', item)"
          )
            td {{ item.code }}
            td {{ item.name }}
            td {{ item.max_fee }}
            td {{ item.updated_at }}
            td.remove-action(
              v-b-tooltip.hover="{ title: $t('company_system.relation_tooltips.has_plans'), placement: 'left', disabled: !item.unremovable }"
            )
              AppIconButton.action-button(
                :disabled="!hasEditPermission || item.unremovable"
                useAppIcon
                icon="trash"
                @click.stop="$emit('remove-row', item)"
              )
</template>

<script>
  // mixins
  import withSorting from "@/mixins/withSorting"

  export default {
    components: {
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    props: {
      items: {
        type: Array,
        default: () => new Array()
      },

      hasEditPermission: {
        type: Boolean,
        default: true
      },

      sortingData: Object
    },

    mixins: [withSorting]
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .cancellation-fee-table
    +listing-container(110px)
    margin-top: 20px
    margin-left: 10px

    table
      +custom-table
      +matchings-table-header-sticky

      table-layout: fixed
      overflow-wrap: break-word

      thead
        th
          max-width: none !important
          margin: 0

          &.option-name
            width: 38%

          &.option-code,
          &.last-updated,
          &.fee-type,
          &.amount-of-money
            width: 14%

          &.actions
            width: 4%
      tbody
        tr
          cursor: pointer

          td
            &.remove-action
              color: $default-purple
              text-align: center

              .action-button
                +icon-button($default-purple)
</style>
